<template>
  <div
    id="mobile-app"
    class="bg-gradient-to-r from-ts_blue_to_new to-ts_blue_from_new text-white p-4 md:p-8 lg:px-12 lg:py-0 rounded-lg pt-10 flex flex-col items-center justify-center relative lg:h-[500px]"
  >
    <div
      class="inner-container flex flex-col lg:flex-row justify-normal items-center lg:justify-center lg:space-x-8 w-full lg:h-full"
    >
      <div class="flex flex-col text-center items-center lg:items-start lg:text-left mb-8 lg:mb-0 z-10">
        <h2 class="text-white text-4xl font-semibold mb-4">
          {{ $t('Travel with the best activities always in your pocket') }}
        </h2>
        <p class="text-white text-lg mb-8 lg:mb-5">{{ $t('Enjoy the app-optimized navigation experience') }}</p>
        <div id="mobile-app-button-container" class="flex space-x-4 justify-center mb-10">
          <a
            class="downloadBadge-wrap flex cursor-pointer items-center m-0"
            target="_blank"
            href="https://apps.apple.com/us/app/id1496550592"
          >
            <img src="/img/app/App_Store_Badge_it.svg" alt="Apple Logo" class="w-[200px]" />
          </a>
          <a
            class="downloadBadge-wrap flex cursor-pointer items-center m-0"
            target="_blank"
            href="https://play.google.com/store/apps/details?id=co.tourscanner.tourscannerwebview&utm_source=web&utm_campaign=footer"
          >
            <img src="/img/app/google-play-badge-it.png" alt="Google Play Logo" class="w-[200px]" />
          </a>
        </div>
      </div>
      <div class="w-full flex justify-center lg:justify-end">
        <SmartImg
          :src="'https://tourscanner.com/blog/wp-content/uploads/hero-mobile.png'"
          class="lg:static lg:bg-no-repeat lg:bg-contain lg:bg-right p-2 lg:p-0 h-2/3 sm:w-auto max-w-2/3 lg:max-h-[600px]"
        />
      </div>
    </div>
  </div>
</template>
